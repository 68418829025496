import { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Alert, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



const ProjectsList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [filters, setFilters] = useState([]);
    const [filtersChosen, setFiltersChosen] = useState([]);
    
    useEffect(()=>{
        document.title = 'Sergey Tumarkin portrolio';
    })


    const getProjectsData = () => {
        fetch('/projects.json')
            .then(res => res.json())
            .then(json => {
                setProjects( json );
                setFilters( json.reduce( (filters, project) => filters.concat([...project.tags.filter( i => !filters.includes(i) )]), [] ) );
            });
    }


    useEffect(() => {
        getProjectsData();
    }, []);


    const toggleFilterChosen = (e,filter) => {
        e.preventDefault();
        setFiltersChosen( filtersChosen => {
            return filtersChosen.includes(filter) ? filtersChosen.filter(i => i!=filter ) : [...filtersChosen, filter]
        })

        console.log(filtersChosen);
    }

    
    const projectsListUI = projects
        .filter( project => filtersChosen.length == 0 ? true : (project.tags.filter(tag => filtersChosen.includes(tag)).length > 0 ))
        .map( project => {
        const {id, name, description, tags, style} = project;
        const tagsUI = tags.map( tag => {
            return <Button variant="link" size="sm" onClick={e=>toggleFilterChosen(e,tag)}>&#x23;{tag}</Button>
        });
        return (
            <Link to={`/${id}`} style={{textDecoration: "none"}}>
                <div key={id} className={`bg-light mb-4 p-3 border rounded-3 border-1 border-secondary}`} style={{background: `linear-gradient(45deg, ${style?.cardColor1}, ${style?.cardColor2})` }}>
                    <h2 className="text-dark">{name}</h2>
                    <p className="text-secondary">{description}</p>
                    <hr />
                    <p className="mb-0">{tagsUI}</p>
                </div>
            </Link>
        )
    })

    const filtersUI = filters.map( filter =>  {
        const style = filtersChosen.includes(filter) ? "outline-secondary" : "link text-secondary text-decoration-none";
        return (
            <Button variant={style +  " my-2 mx-1"} 
                size="sm" 
                onClick={e=>toggleFilterChosen(e,filter)}>
                    &#x23;{filter}
            </Button>
        )
    });

    return (
        <>
            {filtersUI}
            {projectsListUI}
        </>
    )


    
}

export default ProjectsList;