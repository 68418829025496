import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Main from './pages/Main';
import ProjectLanding from './pages/ProjectLanding';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Main/>
          </Route>

          <Route>
            <ProjectLanding/>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
