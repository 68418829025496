import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const  ProjectLanding = () => {
    const [project, setProject] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    let location = useLocation();

    useEffect(()=>{
        setTimeout( () => {
            fetch('/projects.json')
                .then(res => res.json())
                .then(json => {
                    setProject(json.filter( project => location.pathname.indexOf(project.id) == 1 )[0]);
                    setIsLoading( false );
                })
        }, 1000)
    },[])

    useEffect(()=>{
        document.title = project.name;
    }, [project.name])

    
    const {id, name, description, detail, tags, style} = project;

    const tagsUI = tags?.map( tag => {
        return <Button variant="link" size="sm">&#x23;{tag}</Button>
    });

    const projectUI = (
        <div key={id} className={`bg-light mb-4 px-4 py-3 border rounded-3 border-1 border-secondary}`} style={{background: `linear-gradient(45deg, ${style?.cardColor1}, ${style?.cardColor2})` }}>
            <h2 className="text-dark" style={{color: style?.textColor}}>{name}</h2>
            <p className="text-secondary">{description}</p>
            <hr />
            <p className="mb-0">{tagsUI}</p>
        </div>
    );

    const datailUI = detail?.content.map( (contentItem, i)=> {
        const {header, text1, img, youtubeEmbed, text2} = contentItem;

        const imgUI = ( img ?  <Col  className="mr-3"><img src={img} style={{maxHeight: "500px"}}/></Col> : null );

        return (
            <>
                <Row className="mx-4 mt-5">
                    <Col className="m-0 p-0">
                        <h2>{header}</h2>
                    </Col>
                </Row>
                <Row>
                    {i%2==0 ? null : imgUI}
                    <Col className="mt-5 mx-4">
                        <div dangerouslySetInnerHTML={{__html: contentItem.text1}} />
                        {contentItem.youtubeEmbed ? <div className="mt-5" dangerouslySetInnerHTML={{__html: contentItem.youtubeEmbed}} /> : null}
                        {contentItem.text2 ? <div className="mt-5" dangerouslySetInnerHTML={{__html: contentItem.text2}} /> : null}
                    </Col>
                    {i%2==0 ? imgUI : null}
                </Row>
                <Row className="mx-3 my-5">
                    <Col>
                        <hr/>
                    </Col>
                </Row>
            </>
        )
    });

    return (
        <Container>
            <Row>
                <Col className="px-3 py-5">
                    <Link to='/'>&larr; to portfolio</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    {isLoading ? <SkeletonUI /> : projectUI}
                </Col>
            </Row>
            {datailUI}
        </Container>
    )
}


const SkeletonUI = () => {
    return (
        <Alert variant="light">
            <Alert.Heading>
                <Skeleton style={{width: '50%'}}/>
            </Alert.Heading>
            <Skeleton count={5}/>
            <hr />
            <div className="d-flex flex-row">
                <div className="mx-2"><Skeleton style={{width: '50px'}}/></div>
                <div className="mx-2"><Skeleton style={{width: '50px'}}/></div>
                <div className="mx-2"><Skeleton style={{width: '50px'}}/></div>
                
            </div>
        </Alert>
    )
}

export default ProjectLanding;