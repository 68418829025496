
import { useEffect } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ProjectsList from "../components/ProjectsList"


import 'bootstrap/dist/css/bootstrap.min.css';

const Main = () => {
    return (
        <Container >
            <Row className='mt-5'>
                <Col className="px-3" >
                    <img src="/img/tumarkin.png" style={{height: "72px"}}/>
                </Col>
            </Row>
            <Row>
                <Col className="px-3" >
                    <h1 className='display-3 mt-2'>Sergey Tumarkin</h1>
                    <div className='small mt-4 p-2'>
                        <p>
                            <span className="h4">👋</span>&nbsp;
                            Hello! 
                        </p>
                        <p>
                            I'm a web developer with 15+ years background in freelance. Based in Tel-Aviv 🇮🇱
                        </p>
                        <p>
                            My key skills are developing complex Admin UIs, standalone UI like calculators or all kind of forms. Also I have passion for creating Telegram Bots.<br/>
                        </p>
                        <p>
                            Here are some of my recent projects.<br/>
                            Feel free to contact me via Telegram <a href="http://t.me/stumarkin">@stumarkin</a> for any question.
                        </p>
                    </div>
                </Col>
                <Col lg={8}>
                    <ProjectsList />
                </Col>
            </Row>
        </Container>
    )
}

export default Main;